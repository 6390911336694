import { Suspense } from 'react';
import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { TooltipContainer } from "./components/shared/Tooltip";
import TitleBar from "./components/TitleBar";
import './utils/shared/i18n';
import CacheBuster from 'react-cache-buster';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import useGui from './utils/gui';
import LazySafePage from './pages/LazySafePage';
import { NoSession } from './components/login';

import { version } from '../package.json';

function AppContent()
{
  const [authToken] = useGui(s => s.authToken);

  return <>
    <div className="fixed inset-0 h-16 bg-gray-200 dark:bg-gray-800 bg-opacity-50 dark:bg-opacity-50 -z-1" />
    <div className="flex flex-col bg-white dark:bg-black max-w-7xl w-full mx-auto xl:shadow-lg xl:dark:shadow-none">
      <div className="flex items-center h-16 bg-gray-100 dark:bg-gray-900 md:px-4 gap-2 flex-shrink-0">
        <TitleBar />
      </div>
      <Switch>
        <Route path="/account"><LazySafePage page="Account" /></Route>
        <Route>
          {authToken.per?.has('doc')
            ? <LazySafePage page="doctor/App" />
            : authToken.per?.has('mgmt') ? <LazySafePage page="management/App" />
              : <LazySafePage page="patient/App" />}
        </Route>
      </Switch>
    </div>
  </>;
}

const isProduction = process.env.NODE_ENV === 'production';
export default function App()
{
  const [authToken] = useGui(s => s.authToken);

  return <CacheBuster currentVersion={version} isEnabled={isProduction}>
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APP_NAME}`} defaultTitle={process.env.REACT_APP_APP_NAME}></Helmet>
      <Router>
        <Suspense fallback="">
          {authToken ? <AppContent /> : <NoSession />}
        </Suspense>
      </Router>
      <TooltipContainer />
      <ToastContainer
        closeButton={false}
        limit={5}
        hideProgressBar={true}
        toastClassName="bg-white dark:bg-black text-black dark:text-white"
        />
    </HelmetProvider>
  </CacheBuster>;
}
