import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../components/shared/ErrorBoundary";
import Page from "../components/shared/Page";
import { useTitle } from "../utils/gui";
import { Helmet } from "react-helmet-async";
import Loading from "./Loading";
// import Button from "../components/Button";

const pages = new Map();

function ErrorContent({resetErrorBoundary, error})
{
  const {t} = useTranslation();

  const title = t('error.pageCrashed.title', 'Diese Seite ist abgestürzt');

  useTitle(title);

  return <Page>
    <Helmet><title>{title}</title></Helmet>
    <h1 className="text-3xl">{t('error.pageCrashed.heading', "Tut uns sehr Leid :(")}</h1>
    {error && process.env.NODE_ENV === 'development' && <>
      <p>{t('error.pageCrashed.copy', "Hier sind mehr Informationen, vielleicht hilfts?")}</p>
      <div className="ml-4">
        <p className="text-red-600 block"><b>{error.name}</b>: {error.message}</p>
        {error.stack && <samp className="text-red-800 text-xs block whitespace-pre-wrap">{error.stack}</samp>}
      </div>
    </>}
    {resetErrorBoundary && <button onClick={resetErrorBoundary}>{t('error.pageCrashed.reloadPage', 'Seite neu laden')}</button>}
  </Page>
}

export function Error(props)
{
  return <Suspense fallback=""><ErrorContent {...props} /></Suspense>
}

function Fallback(props)
{
  return <Suspense fallback=""><Loading /></Suspense>
}

export default function LazySafePage({page})
{
  let Page = pages.get(page);
  if (!Page) pages.set(page, Page = lazy(() => import(`./${page}`)));

  return <ErrorBoundary key={page} fallback={Error}>
    <Suspense fallback={<Fallback />}>
      <Page />
    </Suspense>
  </ErrorBoundary>
}
