// https://ecmanaut.blogspot.com/2006/07/encoding-decoding-utf8-in-javascript.html
if (typeof escape !== 'function') window.escape = function(s)
{
  function q(c)
  {
    c = c.charCodeAt();
    return '%' + (c < 16 ? '0' : '') + c.toString(16).toUpperCase();
  }

  // eslint-disable-next-line no-control-regex
  return s.replace(/[\x00-),:-?[-^`{-\xFF]/g, q);
};
