export const baseClassName = 'rounded px-2 py-1 disabled:opacity-50 disabled:cursor-default transition'
export const primaryClassName = 'bg-green-500 hover:bg-green-400 active:bg-green-600 dark:bg-green-800 dark:hover:bg-green-700 dark:active:bg-green-900 text-black dark:text-white';
export const secondaryClassName = 'bg-gray-200 dark:bg-gray-700 text-black dark:text-white active:bg-gray-300 dark:active:bg-gray-800';
export const tertiaryClassName = 'bg-gray-300 text-black active:bg-gray-400';
export const dangerClassName = 'bg-red-500 text-white active:bg-red-600';

export default function Button({ primary, danger, submit, className, ...props })
{
  return <button
    type={submit ? 'submit' : 'button'}
    {...props}
    className={`${baseClassName} ${
        primary ? primaryClassName
      : danger  ? dangerClassName
      :           secondaryClassName}
      ${className}`
    } />;
}
