import { useState } from 'react';
import {Link, useHistory, useRouteMatch } from "react-router-dom";
import { Route, Switch } from "react-router";
import { Trans, useTranslation } from 'react-i18next';
import ArrowBackIos from "../icons/ArrowBackIos";
import Button, { baseClassName, primaryClassName } from '../components/Button';
import Input from '../components/Input';
import Close from '../icons/shared/Close';
import useGui from '../utils/gui';
import { Helmet } from 'react-helmet-async';
import ErrorBoxBase from './ErrorBox';

import { version } from '../../package.json';

async function sendLogin(url, payload, setLoggingIn, setError, updateAuthToken, t, onSuccess)
{
  setLoggingIn(true);
  setError();
  try
  {
    const res = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(payload),
    });
    const data = await res.json();
    if ((data && data.error) || !res.ok) throw data;
    window.setTimeout(() =>
    {
      updateAuthToken && updateAuthToken();
      onSuccess && onSuccess();
    }, 1);
  }
  catch (e)
  {
    setError(e && e.error && e.message ? e : {message: t('copy.errorConnecting', 'Fehler bei der Kommunikation mit dem Server. Bitte versuchen Sie es später noch einmal.'), e});
  }
  finally
  {
    setLoggingIn(false);
  }
}

const localizedErrors = {
  passwordAlreadySet: () =>
    <Trans i18nKey="copy.passwordAlreadySet">
      <p>Das Passwort für Ihren Account wurde bereits eingerichtet.</p>
      <p>Bitte gehen Sie auf die <Link to="/" className="underline-dashed hover:underline text-black dark:text-red-200">Startseite</Link> und loggen sich normal ein.</p>
    </Trans>,
  tokenNotFound: () =>
    <Trans i18nKey="copy.tokenNotFound">
      <p>Ihr Passwortlink wurde nicht gefunden oder ist bereits abgelaufen.</p>
      <p>Sie können über die <Link to="/request-password-reset" className="underline-dashed hover:underline text-black dark:text-red-200">Passwort zurücksetzen</Link> Funktion einen neuen anfordern.</p>
    </Trans>,
};

function ErrorBox({error, setError, conciseMessage})
{
  return ErrorBoxBase({error, setError, conciseMessage, localizedErrors});
}

const cardClassName = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded bg-white dark:bg-black shadow-lg dark:shadow-none w-full max-w-lg';
const cardTitleClassName = 'border-b border-gray-200 dark:border-gray-900 px-4 py-2 font-bold text-center relative';
function StaffLogin()
{
  const {t} = useTranslation();
  const [error, setError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [, updateAuthToken] = useGui(s => s.authToken);
  const history = useHistory();

  function tryLogin(e)
  {
    e.preventDefault();

    const userName = e.target.a4mFrontStaffUserName.value.trim();
    if (!userName) return e.target.a4mFrontStaffUserName.focus();
    const password = e.target.a4mFrontStaffPassword.value;
    if (!password) return e.target.a4mFrontStaffPassword.focus();
    const stayLoggedIn = e.target.a4mFrontStayLoggedIn.checked;

    sendLogin('/api/login/staff', {userName, password, stayLoggedIn}, setLoggingIn, setError, updateAuthToken, t);
  }

  const title = t('common.staffLogin', 'Mitarbeiter-Login');

  return <>
    <form className={cardClassName} onSubmit={tryLogin}>
      <Helmet><title>{title}</title></Helmet>
      <div className={cardTitleClassName}>
        <Link to="/" className="absolute left-0 top-0 p-2 flex-shrink-0" onClick={e => { e.preventDefault(); history.goBack(); }}>
          <ArrowBackIos className="w-6 h-6 flex-shrink-0" />
        </Link>
        {title} | {process.env.REACT_APP_APP_NAME}
      </div>
      <div className="flex flex-col gap-2 p-4 sm:mx-12">
        <label htmlFor="a4mFrontStaffUserName">{t('copy.userName', 'Benutzername')}</label>
        <Input name="a4mFrontStaffUserName" id="a4mFrontStaffUserName" autoFocus />

        <label htmlFor="a4mFrontStaffPassword">{t('copy.password', 'Passwort')}</label>
        <Input password name="a4mFrontStaffPassword" id="a4mFrontStaffPassword" />

        <label className="mr-auto mb-4 flex gap-2 items-center"><input type="checkbox" name="a4mFrontStayLoggedIn" />{t('copy.stayLoggedIn', 'Eingeloggt bleiben')}</label>

        <ErrorBox {...{error, setError}} />

        <Button submit primary disabled={loggingIn}>{t('common.login', 'Einloggen')}</Button>
      </div>
    </form>
    <div className="text-xs text-gray-500 text-center absolute w-full bottom-0">{t('version', 'Version')} {version}</div>
  </>;
}

function Login()
{
  const {t} = useTranslation();
  const [error, setError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [, updateAuthToken] = useGui(s => s.authToken);

  function tryLogin(e)
  {
    e.preventDefault();

    const userName = e.target.a4mFrontUserName.value.trim();
    if (!userName) return e.target.a4mFrontUserName.focus();
    const password = e.target.a4mFrontPassword.value;
    if (!password) return e.target.a4mFrontPassword.focus();

    sendLogin('/api/login', {userName, password}, setLoggingIn, setError, updateAuthToken, t);
  }

  return <form className="flex flex-col gap-2 sm:mx-12" onSubmit={tryLogin}>
    <h2 className="text-center text-lg font-bold">{t('copy.login', 'Login')}</h2>

    <label htmlFor="a4mFrontUserName">{t('copy.userName', 'Email')}</label>
    <Input name="a4mFrontUserName" id="a4mFrontUserName" autoFocus />

    <label htmlFor="a4mFrontPassword">{t('copy.password', 'Passwort')}</label>
    <Input password className="mb-4" name="a4mFrontPassword" id="a4mFrontPassword" />

    <ErrorBox {...{error, setError}} />

    <Button submit primary className="mx-auto px-8" disabled={loggingIn}>{t('common.login', 'Einloggen')}</Button>
  </form>;
}

function GuestLogin()
{
  const {t} = useTranslation();
  const [error, setError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [, updateAuthToken] = useGui(s => s.authToken);
  const history = useHistory();
  const setGuestSessionState = useGui(s => s.guestSessionState[1]);

  function tryLogin(e)
  {
    e.preventDefault();

    history.replace('/');
    setGuestSessionState(s => Object.keys(s).forEach(id => delete s[id]));
    sendLogin('/api/start-guest', {}, setLoggingIn, setError, updateAuthToken, t);
  }

  return <form className="flex flex-col gap-4" onSubmit={tryLogin}>
    {t('copy.tryAsGuest', `Sie können die OP Aufklärung vorab völlig unverbindlich as Gast testen.

    Es werden keinerlei Daten übertragen oder gespeichert.`)}

    <ErrorBox {...{error, setError}} />

    <Button submit primary className="mx-auto" disabled={loggingIn}>{t('copy.startGuestSession', 'Gastsitzung starten')}</Button>
  </form>;
}

function base64urlDecode(base64)
{
  return window.decodeURIComponent(window.escape(window.atob(base64.replace(/-/g, '+').replace(/_/g, '/'))));
}

function SetupPassword()
{
  const {t} = useTranslation();
  const {params} = useRouteMatch();
  const [error, setError] = useState();
  const [loggingIn, setLoggingIn] = useState(false);
  const [, updateAuthToken] = useGui(s => s.authToken);
  const history = useHistory();

  try
  {
    const email = base64urlDecode(params.email64);
    const title = t('title.completeSetup', 'Einrichtung abschließen');

    function tryLogin(e)
    {
      e.preventDefault();

      const password = e.target.a4mFrontNewPassword.value;
      if (!password) return e.target.a4mFrontNewPassword.focus();
      const password2 = e.target.a4mFrontNewPasswordRepeat.value;
      if (!password2) return e.target.a4mFrontNewPasswordRepeat.focus();

      if (password === password2) sendLogin('/api/setup-password', {userName: email, password, password2}, setLoggingIn, setError, updateAuthToken, t, () => history.replace('/'));
      else setError({message: t('copy.passwordsDontMatch', 'Die Passwörter stimmen nicht überein. Bitte stellen Sie sicher, dass Sie zweimal das gleiche Passwort eingeben.')})
    }

    return <>
      <Helmet><title>{title}</title></Helmet>
      <div className={cardClassName}>
        <div className={cardTitleClassName}>
          {title} | {process.env.REACT_APP_APP_NAME}
        </div>
        <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
          <p>{t('copy.setupPasswordIntro', `Nur noch ein Schritt, dann können Sie mit der OP Aufklärung starten!`)}</p>
          <p>{t('copy.setupPasswordPrompt', `Bitte vergeben Sie jetzt ein Passwort für Ihren persönlichen Zugang.`)}</p>

          <form className="flex flex-col gap-2 p-4 sm:mx-12" onSubmit={tryLogin}>

            <label htmlFor="a4mFrontUserName">{t('copy.emailAddress', 'Ihre Emailadresse')}</label>
            <Input name="a4mFrontUserName" id="a4mFrontUserName" value={email} readOnly />

            <label htmlFor="a4mFrontNewPassword">{t('copy.newPassword', 'Ihr neues Passwort')}</label>
            <Input password name="a4mFrontNewPassword" id="a4mFrontNewPassword" autoFocus />

            <label htmlFor="a4mFrontNewPasswordRepeat">{t('copy.newPasswordRepeat', 'Passwort bestätigen')}</label>
            <Input className="mb-4" password name="a4mFrontNewPasswordRepeat" id="a4mFrontNewPasswordRepeat" />

            <ErrorBox {...{error, setError}} conciseMessage={t('copy.setupPasswordError', 'Fehler beim Einrichten')} />

            <Button submit primary className="mx-auto px-8" disabled={loggingIn}>{t('common.setupPasswordButton', 'Los geht\'s!')}</Button>
          </form>

          <hr className="border-t border-gray-400 dark:border-gray-600" />

          <Trans i18nKey="copy.setupPasswordGoToLoginPrompt">
            <p>Sie haben Ihr Passwort bereits eingerichtet?</p>
            <Link to="/" className={`${baseClassName} ${primaryClassName} mx-auto px-8`} onClick={e => { e.preventDefault(); history.replace('/'); }}>{t('common.toLogin', 'Zum Login')}</Link>
          </Trans>
        </div>
      </div>
    </>;
  }
  catch
  {
    const title = t('error', 'Fehler');

    return <>
      <Helmet><title>{title}</title></Helmet>
      <div className={cardClassName}>
        <div className={cardTitleClassName}>{title}</div>
        <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
          {t('copy.errorDecodingEmailFromUrl', `Beim Starten der Passworteinrichtung ist ein Fehler aufgetreten.

          Möglicherweise haben Sie einen unvollständigen oder falschen Link angeklickt oder eingegeben.

          Wenn Sie weitere Hilfe benötigen, wenden Sie sich bitte an die Klinik.`)}
        </div>
      </div>
    </>;
  }
}

function Greeter()
{
  const {t} = useTranslation();
  const [loggedOut] = useGui(s => s.loggedOut);

  return <>
    <Helmet><title></title></Helmet>
    <div className={cardClassName}>
      <div className={cardTitleClassName}>{process.env.REACT_APP_APP_NAME}</div>
      <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
        {loggedOut && <>
          {t('copy.loggedOut', `Sie wurden erfolgreich ausgeloggt!

          Sie können dieses Tab jetzt schließen, oder sich wieder einloggen, um die OP Aufklärung fortzusetzen.`)}
          <hr className="border-t border-gray-400 dark:border-gray-600" />
        </>}
        <Login />
        <Link to="/request-password-reset" className="mx-auto text-sm underline-dashed hover:underline">{t('common.resetPassword', 'Passwort zurücksetzen')}</Link>
        <hr className="border-t border-gray-400 dark:border-gray-600" />
        <GuestLogin />
      </div>
    </div>
    <Link to="/staff" className="absolute bottom-2 left-1/2 transform -translate-x-1/2 transition text-gray-500 hover:text-black dark:hover:text-white">{t('common.staffLogin', 'Mitarbeiter-Login')}</Link>
  </>;
}

function RequestPasswordReset()
{
  const {t} = useTranslation();
  const [error, setError] = useState();
  const [requesting, setRequesting] = useState(false);
  const [requested, setRequested] = useState(false);

  function tryReset(e)
  {
    e.preventDefault();

    const userName = e.target.a4mFrontUserName.value.trim();
    if (!userName) return e.target.a4mFrontUserName.focus();

    sendLogin('/api/request-password-reset', {userName}, setRequesting, setError, null, t, () => setRequested(true));
  }

  const title = t('title.requestPasswordReset', 'Passwort zurücksetzen');

  return <>
    <Helmet><title>{title}</title></Helmet>
    <div className={cardClassName}>
      <div className={cardTitleClassName}>{title}</div>
      <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
        {t('copy.requestPasswordReset', `Wenn Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen.

Sie bekommen per Email einen Link zugesandt, mit dem Sie ein neues Passwort vergeben können. Dieser Link ist 24 Stunden lang gültig.

Ihr bestehendes Passwort bleibt unverändert, bis Sie ein neues vergeben.

Bitte geben Sie die Emailadresse an, die Sie zum einloggen benutzen.`)}
          <form className="flex flex-col gap-2 p-4 sm:mx-12" onSubmit={tryReset}>

            <label htmlFor="a4mFrontUserName">{t('copy.emailAddress', 'Ihre Emailadresse')}</label>
            <Input name="a4mFrontUserName" id="a4mFrontUserName" />

            <ErrorBox {...{error, setError}} conciseMessage={t('copy.requestPasswordResetError', 'Fehler beim Zurücksetzen')} />

            {requested
              ? <div className="text-green-900 dark:text-green-400 bg-green-200 dark:bg-green-900 dark:bg-opacity-30 border-green-300 dark:border-green-900 border text-sm rounded p-2 relative">
                <button type="button" onClick={() => setRequested()} className="absolute top-0 right-0 p-2"><Close className="w-3 h-3" /></button>
                <div className="font-bold">{t('common.passwordResetSent', 'Email wurde versandt')}:</div>
                <p>{t('copy.passwordResetSent', 'Bitte sehen Sie in Ihrem Emailpostfach nach und folgen Sie den Anweisungen in der Email.')}</p>
              </div>
              : <Button submit primary className="mx-auto px-8" disabled={requesting}>{t('common.requestPasswordResetButton', 'Passwort zurücksetzen')}</Button>}
            <Link to="/" className="mx-auto text-sm underline-dashed hover:underline">{t('common.backToLogin', 'Zurück zum Login')}</Link>
          </form>
      </div>
    </div>
  </>;
}

function ResetPassword()
{
  const {t} = useTranslation();
  const {params} = useRouteMatch();
  const [error, setError] = useState();
  const [requesting, setRequesting] = useState(false);
  const [, updateAuthToken] = useGui(s => s.authToken);
  const history = useHistory();

  try
  {
    const token = base64urlDecode(params.token64);
    const title = t('title.resetPassword', 'Passwort zurücksetzen');

    function tryReset(e)
    {
      e.preventDefault();

      const userName = e.target.a4mFrontUserName.value.trim();
      if (!userName) return e.target.a4mFrontUserName.focus();
      const password = e.target.a4mFrontNewPassword.value;
      if (!password) return e.target.a4mFrontNewPassword.focus();
      const password2 = e.target.a4mFrontNewPasswordRepeat.value;
      if (!password2) return e.target.a4mFrontNewPasswordRepeat.focus();

      if (password === password2) sendLogin('/api/reset-password', {userName, password, password2, token}, setRequesting, setError, updateAuthToken, t, () => history.replace('/'));
      else setError({message: t('copy.passwordsDontMatch', 'Die Passwörter stimmen nicht überein. Bitte stellen Sie sicher, dass Sie zweimal das gleiche Passwort eingeben.')})
    }

    return <>
      <Helmet><title>{title}</title></Helmet>
      <div className={cardClassName}>
        <div className={cardTitleClassName}>{title}</div>
        <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
          {t('copy.resetPassword', `Wenn Sie Ihr Passwort vergessen haben, können Sie es hier zurücksetzen.

Ihr bestehendes Passwort bleibt unverändert, bis Sie ein neues vergeben. Sollte Ihnen Ihr Passwort wieder einfallen, können Sie dieses einfach weiter verwenden.

Falls Sie die Zurücksetzung Ihres Passworts nicht selbst angefordert haben, empfehlen wir Ihnen, sich baldmöglichst einzuloggen, um Ihren Account zu schützen und den Link zu entwerten.`)}
            <form className="flex flex-col gap-2 p-4 sm:mx-12" onSubmit={tryReset}>

              <label htmlFor="a4mFrontUserName">{t('copy.emailAddress', 'Ihre Emailadresse')}</label>
              <Input name="a4mFrontUserName" id="a4mFrontUserName" autoFocus />

              <label htmlFor="a4mFrontNewPassword">{t('copy.newPassword', 'Ihr neues Passwort')}</label>
              <Input password name="a4mFrontNewPassword" id="a4mFrontNewPassword" />

              <label htmlFor="a4mFrontNewPasswordRepeat">{t('copy.newPasswordRepeat', 'Passwort bestätigen')}</label>
              <Input className="mb-4" password name="a4mFrontNewPasswordRepeat" id="a4mFrontNewPasswordRepeat" />

              <ErrorBox {...{error, setError}} conciseMessage={t('copy.resetPasswordError', 'Fehler beim Zurücksetzen')} />

              <Button submit primary className="mx-auto px-8" disabled={requesting}>{t('common.requestPasswordResetButton', 'Passwort zurücksetzen')}</Button>
              <Link to="/" className="mx-auto text-sm underline-dashed hover:underline">{t('common.backToLogin', 'Zurück zum Login')}</Link>
            </form>
        </div>
      </div>
    </>;
  }
  catch
  {
    const title = t('error', 'Fehler');

    return <>
      <Helmet><title>{title}</title></Helmet>
      <div className={cardClassName}>
        <div className={cardTitleClassName}>{title}</div>
        <div className="p-4 flex flex-col gap-4 whitespace-pre-line text-justify">
          {t('copy.errorDecodingTokenFromUrl', `Beim Starten der Passwortzurücksetzung ist ein Fehler aufgetreten.

          Möglicherweise haben Sie einen unvollständigen oder falschen Link angeklickt oder eingegeben.

          Wenn Sie weitere Hilfe benötigen, wenden Sie sich bitte an die Klinik.`)}
        </div>
      </div>
    </>;
  }
}

export function NoSession()
{
  return <Switch>
    <Route path="/setup-password/:email64"><SetupPassword /></Route>
    <Route path="/request-password-reset"><RequestPasswordReset /></Route>
    <Route path="/reset-password/:token64"><ResetPassword /></Route>
    <Route path="/staff"><StaffLogin /></Route>
    <Route><Greeter /></Route>
  </Switch>;
}
