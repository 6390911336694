import { useTranslation } from 'react-i18next';
import Close from '../icons/shared/Close';

export default function ErrorBox({error, setError, conciseMessage, localizedErrors})
{
  const { t } = useTranslation();

  if (!error) return null;

  const LocalizedError = localizedErrors && localizedErrors[error.key];

  return <div className="text-red-900 dark:text-red-400 bg-red-200 dark:bg-red-900 dark:bg-opacity-30 border-red-300 dark:border-red-900 border text-sm rounded p-2 relative">
    <button type="button" onClick={() => setError()} className="absolute top-0 right-0 p-2"><Close className="w-3 h-3" /></button>
    <div className="font-bold">{conciseMessage || t('copy.loginError', 'Fehler beim Login')}:</div>
    {LocalizedError ? <LocalizedError /> : error.message}
    {error.e && process.env.NODE_ENV === 'development' && <div className="text-black text-xs">{error.e.message}</div>}
  </div>;
}
