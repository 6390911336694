import produce from "immer";
import create from "zustand";
import fscreen from 'fscreen';
import { useEffect, useState, useCallback } from "react";
import { parseAuthToken, getCookie } from "./shared/gui";
import { stringify, tryParse } from "./shared/json";

const useGui = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  function setFullscreen(enable)
  {
    set(s => { if (s.fullscreen[0] !== enable) s.fullscreen[0] = enable });
  }

  function setter(key, doSet)
  {
    doSet = doSet || set;
    return v => doSet(s => { s[key][0] = v; });
  }

  function addSimpleFields(to, simpleFields, doSetter)
  {
    doSetter = doSetter || setter;
    for (let key in simpleFields)
    {
      const field = simpleFields[key];
      const [defaultValue, doSet] = Array.isArray(field) ? field : [field];
      to[key] = [defaultValue, doSetter(key, doSet)];
    }
  }

  fscreen.onfullscreenchange = () => setFullscreen(!!fscreen.fullscreenElement);

  function handleKey(e)
  {
    set(s => { s.shiftKey = e.shiftKey; });
  }

  window.addEventListener('keydown', handleKey);
  window.addEventListener('keyup', handleKey);

  const fields = {
    fullscreen: [false, setFullscreen],
    authToken: [getAuthToken(), () => set(s => { s.authToken[0] = getAuthToken(); })],
    shiftKey: false,
    sessionState: [{}, fn => set(s => { fn(s.sessionState[0]); })],
    guestSessionState: [{}, fn => set(s => { fn(s.guestSessionState[0]); })],
  };

  const simpleFields = {
    pageTitle: null,
    pageSubTitle: null,
    loggedOut: false,
  };

  addSimpleFields(fields, simpleFields);

  return fields;
});

function getAuthToken()
{
  return parseAuthToken(getCookie('login'));
}

export function useTitle(text, backPath)
{
  const setTitle = useGui(s => s.pageTitle[1]);
  useEffect(function()
  {
    setTitle({text, backPath});
    return setTitle;
  }, [text, backPath, setTitle]);
}

export function useSubTitle(text)
{
  const setSubTitle = useGui(s => s.pageSubTitle[1]);
  useEffect(function()
  {
    setSubTitle(text);
    return setSubTitle;
  }, [text, setSubTitle]);
}

export const entryToOption = e => ({value: e[0], label: e[1].name});

export function useLocalStorage(key, initial = undefined)
{
  const stored = window.localStorage.getItem(key);
  const [value, doSet] = useState(stored !== null ? tryParse(stored, initial) : initial);
  const set = useCallback(v => doSet(s =>
  {
    if (typeof v === 'function') v = v(s);
    window.localStorage.setItem(key, stringify(v));
    return v;
  }), [doSet, key]);
  return [value, set];
}

export default useGui;
