import {useState, useEffect} from "react";
import {NavLink, useHistory} from "react-router-dom";
import LightMode from "../icons/LightMode";
import ModeNight from "../icons/ModeNight";
import Fullscreen from "../icons/shared/Fullscreen";
import FullscreenExit from "../icons/shared/FullscreenExit";
import useGui from "../utils/gui";
import fscreen from 'fscreen';
import { useTranslation } from 'react-i18next';
import Logout from "../icons/shared/Logout";
import { useTooltip } from "./shared/Tooltip";
import { Link } from "react-router-dom";
import ArrowBackIos from "../icons/ArrowBackIos";
import Person from "../icons/shared/Person";
import Doctor from "../icons/Doctor";

async function toggleFullscreen()
{
  const [fullscreen, setFullscreen] = useGui.getState().fullscreen;
  try
  {
    if (!fullscreen)
    {
      await fscreen.requestFullscreen(document.documentElement);
      setFullscreen(true);
    }
    else
    {
      await fscreen.exitFullscreen();
      setFullscreen(false);
    }
  }
  catch
  {
    setFullscreen(false);
  }
}

function FullscreenButton()
{
  const {t} = useTranslation();
  const [fullscreen] = useGui(s => s.fullscreen);
  const tooltip = useTooltip(fullscreen ? t('tool.leaveFullscreen', 'Vollbild verlassen') : t('tool.fullscreen', 'Vollbild'));

  return fscreen.fullscreenEnabled && <button type="button" className="p-1" onClick={toggleFullscreen} {...tooltip}>
    {fullscreen ? <FullscreenExit className="w-6 h-6" /> : <Fullscreen className="w-6 h-6" />}
  </button>;
}

export function DarkModeSwitch()
{
  const [isDark, setIsDark] = useState(localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches));
  useEffect(() =>
  {
    localStorage.theme = isDark ? 'dark' : 'light';
    document.documentElement.classList[isDark ? 'add' : 'remove']('dark');
  }, [isDark]);

  return <label className="flex flex-col md:flex-row items-center gap-0.5 md:gap-1 py-2 cursor-pointer">
    <input type="checkbox" className="hidden" checked={isDark} onChange={e => setIsDark(e.target.checked)} />
    <LightMode className="w-3 h-3 md:w-4 md:h-4" />
    <div className="flex w-3 h-5 md:w-12 md:h-6 rounded-full bg-gray-300 dark:bg-gray-700">
      <div className={`w-2 h-2 md:w-4 md:h-4 rounded-full bg-white dark:bg-gray-900 transition-all m-auto ${isDark ? 'mt-2.5 md:mt-auto md:ml-6.5' : 'mt-0.5 md:mt-auto md:ml-1'}`} />
    </div>
    <ModeNight className="w-3 h-3 md:w-4 md:h-4" />
  </label>;
}

function LogoutButton()
{
  const {t} = useTranslation();
  const [authToken, updateAuthToken] = useGui(s => s.authToken);
  const [, setLoggedOut] = useGui(s => s.loggedOut);
  const history = useHistory();

  async function logout()
  {
    document.cookie = 'login=';
    const wasUser = !!authToken.una;
    try
    {
      const res = await fetch('/api/logout', {method: 'POST'});
      const data = await res.json();
      setLoggedOut(true);
      if ((data && data.error) || !res.ok) throw data;
    }
    catch
    {
    }
    finally
    {
      updateAuthToken();
      fscreen.fullscreenElement && fscreen.exitFullscreen();
      wasUser && history.replace('/staff');
    }
  }

  return <button type="button" className="p-1" onClick={logout} {...useTooltip(t('tool.logout', 'Ausloggen'))}><Logout className="w-6 h-6" /></button>;
}

function Separator()
{
  return <div className="m-3 w-0.5 self-stretch bg-gray-300 dark:bg-gray-700" />;
}

function UserName()
{
  const [authToken] = useGui(s => s.authToken);
  const {t} = useTranslation();

  return <div className="dark:text-gray-400 dark:hover:text-white transition">
    <NavLink to="/account" className="flex flex-col items-center leading-tight" activeClassName="dark:text-white">
      {!authToken.per || authToken.per.has('guest')
        ? <span className="font-bold">{t('common.guest', 'Gast')}</span>
        : authToken.una
          ? <>
            {authToken.per?.has('doc')
              ? <Doctor className="w-5 h-5" />
              : <Person className="w-5 h-5" />}
            <span className="font-bold">{authToken.una}</span>
          </>
          : <>
            <span>{authToken.per.has('doc') && <span className="font-bold">Dr. </span>}{authToken.fna}</span>
            <span className="font-bold">{authToken.lna}</span>
          </>}
    </NavLink>
  </div>;
}

function Title()
{
  const {text, backPath} = useGui(s => s.pageTitle[0] || {});
  const history = useHistory();

  const isLink = typeof backPath === 'string';
  const BackComponent = backPath && (isLink ? Link : 'button');
  const backProps = backPath && {
    to: isLink ? backPath : undefined,
    type: isLink ? undefined : 'button',
    className: 'p-2',
    onClick: e => { e.preventDefault(); history.goBack(); },
  };

  return <div className={`mr-auto ${!backPath && 'ml-4'}`}>
    {typeof text === 'string'
      ? <h1 className="text-xl md:text-2xl leading-none flex items-center gap-2">
        {backPath && <BackComponent {...backProps}><ArrowBackIos className="w-8 h-8 flex-shrink-0" /></BackComponent>}
        {text}
      </h1>
      : text}
  </div>;
}

export default function TitleBar()
{
  return <>
    <Title />

    <FullscreenButton />
    <DarkModeSwitch />

    <Separator />

    <UserName />
    <LogoutButton />
  </>;
}
