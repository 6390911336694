import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import Page from "../components/shared/Page";
import Spinner from "../components/Spinner";
import { useTitle } from "../utils/gui";

export default function Loading()
{
  const {t} = useTranslation();
  const title = t('copy.loading', 'Lade...');
  useTitle(title);

  return <Page className="flex-grow flex">
    <Helmet><title>{title}</title></Helmet>
    <Spinner huge className="m-auto text-gray-400 dark:text-gray-700" />
  </Page>;
}
